/* global $ */
class ADA {
	constructor(options = {}) {
		const img = $("img");
		this.nav = (options.navId ? $("#" + options.navId + "") : $("#nav-main"));
		this.navDropLinks = this.nav.find(".drop");
		console.log("%c\u2713 %cADA %cModule %cis installed", "font-size:2em", "font-weight:bold; font-size:1.5em;color: #dc3545;", " color: #444; font-weight:bold; font-size:1.5em;", "font-weight:normal; font-size:1em;");

		//set html5 element roles
		this.nav.attr({"aria-label": "Main Navigation", "role": "navigation"});
		$("header").attr("role", "banner");
		$("footer").attr("role", "contentinfo");
		$("article").attr("role", "article");
		$("aside").attr("role", "article");
		$("section").attr("role", "region");

		//set form required
		$("form input[required]").each(function () {
			$(this).attr("aria-required", "true");
		});


		//if img element is missing an alt attribute, add
		if (typeof img !== typeof undefined && img !== false) {
			$(this).attr("alt", "");
		}

		this.navDropLinks.find(">a").attr({"aria-haspopup": "true", "aria-expanded": "false"});

		if (window.matchMedia("(min-width: 769px)").matches) {
			this.setADAProps(this.nav.find("ul li a"),"show");
			this.setADAProps(this.nav.find("ul ul li a"),"hide");
		} else {
			this.setADAProps(this.nav.find("ul li a"),"hide");
		}
	}

	setADAProps(el, state) {
		const ariaHidden = (state === "show" ? "false" : "true");
		const tabindex = (state === "show" ? "0" : "-1");
		el.attr({
			"aria-hidden": ariaHidden,
			"tabindex": tabindex
		});
	}

	toggleExpandedProp(el) {
		el.attr("aria-expanded") === "false" ? el.attr("aria-expanded", "true") : el.attr("aria-expanded", "false");
	}

	toggleHiddenProps(el) {
		el.attr("aria-hidden") === "false" ? el.attr({
			"aria-hidden": "true",
			"tabindex": "-1"
		}) : el.attr({"aria-hidden": "false", "tabindex": "0"});
	}
}